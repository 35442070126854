.input-group-addon {
	border-left: 1px solid #eee !important;
}

.password-eye {
	transition: 0.3s;
	opacity: 0.5;
}

.password-show {
	&:hover { 
		.password-eye {
			opacity: 1;
		}
	}

	&:focus, &:active {
		.password-eye {
			color: #5C6BC0;
		}
	}
}

.btn-primary {
	transition: 0.3s;
	background: #5C6BC0;
	border-color: #5C6BC0;
	color: #fff !important;

	&:hover {
		background: lighten(#5C6BC0, 15%) !important;
		border-color: lighten(#5C6BC0, 15%) !important;
	}
}

.form-control {
	&:focus, &:active {
		~ .input-group-addon {
			border-left: 1px solid #aaa !important;
		}
	}
}