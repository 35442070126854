.sidebar{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 260px;
    display: block;
    z-index: 1;
    color: #fff;
    font-weight: 200;
    background: $sidebar-bg;
    
    .sidebar-wrapper{
        position: relative;
        max-height: none;
        min-height: 100%;
        overflow: hidden;
        width: 260px;
        z-index: 4;
    }
    
    .sidebar-background{
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;
    }
    
    .logo{
        padding: $navbar-padding-a;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        
        p{
            float: left;
            font-size: 20px;
            margin: 10px 10px;
            color: $white-color;
            line-height: 20px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }
        
        .simple-text{
            text-transform: uppercase;
            padding: $padding-small-vertical $padding-zero;
            display: block;
            font-size: $font-size-large;
            color: $white-color;
            text-align: center;
            font-weight: $font-weight-bold;
            line-height: 30px;
        }
    }
    
    .logo-tim{
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;
        
        img{
            width: 60px;
            height: 60px;
        }
    }
    
    .nav{
        margin-top: 20px;
        
        li{
            > a{
                color: #FFFFFF;
                margin: 5px 15px;
                opacity: .86;
                border-radius: 4px;
            }
            
            &:hover > a{
                background: rgba(255,255,255,0.13);
                opacity: 1;
            }
            
            &.active > a{
                color: #FFFFFF;
                opacity: 1;
                background: rgba(255,255,255,0.23);  
  
            }

            &.bottom-link {
                position: absolute;
                width: 100%;
                bottom: 10px;
            }

            &.bottom-link a{
                background: rgba(255, 255, 255, 0.14);
                opacity: 1;
                color: #FFFFFF;
            }
        }
        
        p{
            margin: 0;
            line-height: 30px;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
        }
        
        i{
            font-size: 28px;
            float: left;
            margin-right: 15px;
            line-height: 30px;
            width: 30px;
            text-align: center;
        }
    }
}


.sidebar,
body > .navbar-collapse{
    background: $sidebar-bg;
    
    .logo{
        padding: $navbar-padding-a;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        
        p{
            float: left;
            font-size: 20px;
            margin: 10px 10px;
            color: $white-color;
            line-height: 20px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }
        
        .simple-text{
            text-transform: uppercase;
            padding: $padding-small-vertical $padding-zero;
            display: block;
            font-size: $font-size-large;
            color: $white-color;
            text-align: center;
            font-weight: $font-weight-bold;
            line-height: 30px;
        }
    }
    
    .logo-tim{
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;
        
        img{
            width: 60px;
            height: 60px;
        }
    }
}


.main-panel{
    background: rgba(203,203,210,.15);
    position: relative;
    z-index: 2;
    float: right;
    width: $sidebar-width;
    min-height: 100%;
    
    > .content{
        padding: 30px 15px;
        min-height: calc(100% - 123px);
    }
    
    > .content h2{
        margin-top: 0 !important;
    }
    
    > .footer{
        border-top: 1px solid #e7e7e7;
    }
    
    .navbar{
        margin-bottom: 0;
    }
}

.sidebar,
.main-panel{
    overflow: auto;
    max-height: 100%;
    height: 100%;
    -webkit-transition-property: top,bottom;
    transition-property: top,bottom;
    -webkit-transition-duration: .2s,.2s;
    transition-duration: .2s,.2s;
    -webkit-transition-timing-function: linear,linear;
    transition-timing-function: linear,linear;
    -webkit-overflow-scrolling: touch;
}
