
// Fonts
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700);

// Font Awesome
@import url(http://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Animate CSS
@import "../dashboard/sass/animate";

// Icons CSS
@import "../dashboard/sass/icons";

// Dashboard
@import "../dashboard/sass/light-bootstrap-dashboard";

// Auth
@import "auth";

// Our own CSS
// Dashboard-specific
.table {
  .remove-button {
    background: transparent;
    border: none;
    color: #7986CB;

    &:hover span {
      color: #96a1d6;
    }
  }
}

.mark-all {
  color: #7986CB !important;
  cursor: pointer;

  &:hover {
    color: #96a1d6 !important;
  }
}

.delete-selected {
  display: none;
}